<template>
  <common-page-edit 
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="ShowAttrEntity"
    routeNameCreate="CreateAttrEntity"
    :keyRoute="'AttrEntityId'"
    :backUrl="'/attrentity'"
    :hasLang="false"
  />
</template>
<script>

import { API_METHODS } from "../../../../constant";
import { NAME_OBJECT } from "../../../../RoleConstanans"
import CommonPageEdit from '../../commonElement/CommonPageEdit.vue';

export default {
  name: "EditPageDynamic",
  components: {
    CommonPageEdit
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "EntityDynamicShow",
      routeNameCreate: "CreatePageDynamic",

      apiNameGet: API_METHODS.GET_ATTR_ENTITY,
      apiNameCreate:API_METHODS.CREATE_ATTR_ENTITY,
      apiNameUpdate:API_METHODS.UPDATE_ATTR_ENTITY
    };
  },
  computed: {
    nameEnity(){
      return this.$route.params.attrEntity
    },
    title() {
      let text1 = this.$t('Просмотр атрибута');
      let text2 = this.$t('Создание атрибута объекта');
      let text3 = this.$t('Редактирование атрибута');

      let findShow = this.$route.path.indexOf('show')
      if(findShow !=-1) {
        return text1
      }
      let findCreate = this.$route.path.indexOf('create')
      if(findCreate !=-1) {
        return text2
      }
      let findEdit = this.$route.path.indexOf('edit')
      if(findEdit !=-1) {
        return text3
      }
      return text1
    },
   nameRoleEntity(){
         return NAME_OBJECT.attrEntity
   },
  }
};
</script>
